$tablet-width-min: 600px;
$tablet-width-max: 780px;

.do,
.dont {
  border-style: solid;
  padding: 20px;
  border-bottom: none;
  border-left: none;
  border-right: none;

  &:last-child {
    border-bottom: 3px solid;
  }

  i {
    display: block;
    text-align: center;
  }
}

.dont {
  border-right: none;

  i {
    color: #ffcc00;
  }
}

.do {
  i {
    color: #009900;
  }
}

.break {
  break-inside: avoid;
}

.infotitle {
  margin: auto;
}

.info {
  column-count: 2;
  column-gap: 0;
  margin: 3px;
  text-align: center;

  h2 {
    margin-bottom: 0;
  }

  .dont {
    height: 11em;
    h2 {
      color: #ffcc00;
    }
  }

  .do {
    height: 11em;
    h2 {
      color: #009900;
    }
  }

  p {
    margin-top: 0;
  }

  * > .fa-solid {
    font-size: 100px;
  }

  @media (max-width: $tablet-width-min) {
    margin: 2px;

    .dont {
      padding: 5px;
      height: 20em;
    }

    .do {
      padding: 5px;
      height: 20em;
    }
  }

  @media (max-width: $tablet-width-max) and (min-width: $tablet-width-min) {
    .dont {
      height: 12em;
    }

    .do {
      height: 12em;
    }
  }
}
