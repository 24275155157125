$tablet-width-min: 600px;

p,
li > a {
  word-wrap: break-word;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  font-size: 1rem;
}

// for printing
@media print {
  header,
  .buttons-section {
    display: none !important;
  }
}
