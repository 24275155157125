$mobile-width-max: 599px;
$tablet-width-min: 600px;

#managing-common-responses {
  display: flex;

  @media (max-width: $mobile-width-max) {
    flex-direction: column;
  }

  .buttons {
    display: flex;

    button {
      height: auto;
      min-height: 36px;
    }

    @media (max-width: $mobile-width-max) {
      flex-wrap: wrap;
      justify-content: space-between;

      .mdc-touch-target-wrapper {
        width: 45%;
      }
    }
    @media (min-width: $tablet-width-min) {
      flex-direction: column;
    }
  }

  .image-with-speech-bubble-mobile {
    @media (max-width: $mobile-width-max) {
      position: relative;

      img {
        margin-top: calc(3em + 11px);
        width: 100%;
      }

      .speech-bubble {
        background-color: white;
        position: absolute;
        top: 0;
        margin: 0;
        line-height: 1.5em;
        padding: 8px;
        border: 3px solid #000000;
        border-radius: 10px;
        font-size: 12px;
        width: calc(100% - 22px);
        min-height: 3em;
      }

      .speech-bubble:before {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 34%;
        border-top: 20px solid;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        display: block;
        width: 0;
      }

      .speech-bubble:after {
        content: '';
        position: absolute;
        bottom: -16px;
        left: 35%;
        border-top: 16px solid white;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        display: block;
        width: 0;
      }
    }
    @media (min-width: $tablet-width-min) {
      display: none;
    }
  }

  .image-with-speech-bubble-tablet {
    @media (max-width: $mobile-width-max) {
      display: none;
    }
    @media (min-width: $tablet-width-min) {
      position: relative;
      margin-left: 16px;
      width: 40%;

      img {
        width: 100%;
      }

      .speech-bubble {
        background-color: white;
        position: absolute;
        right: 1%;
        top: 1%;
        margin: 0;
        line-height: 1.5em;
        padding: 8px;
        border: 3px solid #000000;
        border-radius: 10px;
        font-size: 12px;
        width: 100px;
        min-height: 9em;
      }

      .speech-bubble:before {
        content: '';
        position: absolute;
        top: 32px;
        left: -20px;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid;
        display: block;
        width: 0;
      }

      .speech-bubble:after {
        content: '';
        position: absolute;
        top: 35px;
        left: -16px;
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
        border-right: 17px solid white;
        display: block;
        width: 0;
      }
    }
  }
}
