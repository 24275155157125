.triangle-border {
  position: relative;
  padding: 15px;
  margin: 1em 0 3em;
  border: 3px solid #000000;
  /* css3 */
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.triangle-border:before {
  content: '';
  position: absolute;
  bottom: -38px; /* value = - border-top-width - border-bottom-width */
  left: 50%; /* controls horizontal position */
  border-width: 35px 20px 0;
  border-style: solid;
  border-color: #000000 transparent;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0;
}

/* creates the smaller  triangle */
.triangle-border:after {
  content: '';
  position: absolute;
  bottom: -33px; /* value = - border-top-width - border-bottom-width */
  left: 50.2%; /* value = (:before left) + (:before border-left) - (:after border-left) */
  border-width: 33px 19px 0;
  border-style: solid;
  border-color: #fff transparent;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0;
}

.pic {
  display: block;
  max-width: 100%;
  margin-top: -50px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  width: 100%;
  max-width: 600px;
  margin: auto;
}
