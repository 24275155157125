@use 'sass:color';

@use '@material/theme' with (
  $primary: #1a7eae,
  $secondary: #7c5295
);

@use '@material/banner';
@use '@material/banner/styles';
@use '@material/button/mdc-button';
@use '@material/button';
@use '@material/card';
@use '@material/checkbox/mdc-checkbox';
@use '@material/data-table/data-table';
@use '@material/drawer';
@use '@material/form-field/_index.scss' as form-field;
@use '@material/form-field/mdc-form-field';
@use '@material/icon-button';
@use '@material/icon-button/styles' as icon-button-styles;
@use '@material/image-list';
@use '@material/image-list/mdc-image-list';
@use '@material/linear-progress';
@use '@material/list/mdc-list';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/menu/mdc-menu';
@use '@material/radio';
@use '@material/radio/styles' as radio-styles;
@use '@material/slider/styles' as slider-styles;
@use '@material/select/mdc-select';
@use '@material/select/styles' as selectStyles;
@use '@material/textfield/icon';
@use '@material/textfield/mdc-text-field';
@use '@material/top-app-bar/mdc-top-app-bar';
@use '@material/typography';
@use '@material/typography/mdc-typography';

@include card.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;
@include drawer.core-styles;
@include drawer.dismissible-core-styles;
@include drawer.modal-core-styles;
@include form-field.core-styles;
@include linear-progress.core-styles;

$form-field-width: 430px;
$mobile-form-field-width: 100%;
$mobile-width-max: 599px;
$tablet-width-min: 600px;
$tablet-width-max: 979px;

$success: green;
$danger: #b0001f;

body {
  height: 100%;
  margin: 0;
}

h1 {
  @include typography.typography(headline4);
}

h2 {
  @include typography.typography(headline5);
}

h3 {
  @include typography.typography(headline5);
}

h4,
h5,
h6 {
  @include typography.typography(headline5);
}

h5 {
  font-size: 1.35rem;
}

h6 {
  font-size: 1.3rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

a,
div,
label,
li,
p,
span,
td,
th,
.mdc-data-table__cell {
  @include typography.typography(body1);
  @media (min-width: $tablet-width-min) {
    font-size: 20px;
  }
}

strong a,
div,
label,
li,
p,
span,
td,
th,
.mdc-data-table__cell {
  font-weight: unset;
}

.f-16 {
  font-size: 16px;
}

.mb-5 {
  margin-bottom: 5px;
}

#body-content {
  padding: 2em 0 0 0;
  max-width: 855px;
  margin: auto;
  padding: 2em;

  a {
    &.mdc-button {
      text-decoration: none;
    }
  }

  img {
    border: 1px solid black;
    max-width: 100%;
    height: auto;
  }

  .page-content a {
    text-decoration: underline;
  }

  .page-spacer {
    @each $size in 1, 2, 3, 4, 5 {
      &.x#{$size} {
        height: #{$size}rem;
      }
    }
  }

  .accessible-h1 {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    white-space: nowrap;
    width: 1px;
    height: 1px;
  }

  .alert-warning {
    color: $danger;
  }
}

#login,
#contact {
  max-width: 500px;
  margin: 0 auto;

  #logo {
    border: none;
    display: block;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }

  #password-toggle-icon {
    margin-left: 8px;
    margin-top: 34px;
    padding-left: 18px;
    padding-top: 17px;
    width: 38px;
    height: 37px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 5px;

    @media (max-width: $tablet-width-min) {
      margin-top: 32px;
    }
  }
}

#contact-us-form {
  .mdc-list-item__content,
  .mdc-list-item__primary-text {
    white-space: unset;
  }
  @media (max-width: $tablet-width-min) {
    .mdc-list-item {
      border-bottom: 1px solid theme.$primary;
    }
  }
}

.display-none {
  display: none;
}

.warning {
  background-color: pink;
}

.danger {
  color: $danger;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 750px;

  &#page-form {
    max-width: unset;
    @media (max-width: $tablet-width-min) {
      margin-left: -2em;
      margin-right: -2em;
    }
  }

  label,
  .mdc-select {
    margin-top: 0.5em;

    .field_with_errors {
      width: 100%;
    }
  }
}

nav {
  ol,
  li {
    list-style: none;
  }

  ol {
    padding: 1rem 0;
  }

  li {
    float: left;
  }

  li::after {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    content: ' / ';
  }

  li:last-child::after {
    display: none;
  }
}

.bold-class {
  font-weight: bolder;
}

.mdc-top-app-bar__title,
.fa-bars {
  color: theme.$background;
}

.mdc-top-app-bar__row {
  @media (max-width: $mobile-width-max) {
    margin-bottom: 0.5rem;
  }
}

.favorited {
  color: red;
  --fa-animation-iteration-count: 2;
  --fa-animation-duration: 0.2s;
  --fa-beat-fade-scale: 2;
}

.favorites {
  outline: 1px solid rgba(0, 0, 0, 0.125);
  outline-offset: 25px;
}

#favorite-btn {
  margin: 0 50%;
  padding-top: 10px;

  #favorite-toggle {
    max-width: 50px;
    margin: 0 -25px;
  }
}

#favorite-btn-uncentered {
  #favorite-btn {
    margin: unset;
    padding-top: unset;

    #favorite-toggle {
      max-width: unset;
      margin: unset;
    }
  }
}

#home-image-list {
  padding-left: 0;
  .mdc-list-item {
    padding-left: 0;
    padding-right: 0;
  }
  .home-image-list-item {
    border: 3px solid black;
    border-radius: 5px;
    margin-bottom: 1em;
    padding: 4px;
    a {
      padding: 5px;
    }
    .home-page-image-overlay.active {
      background: #eee;
      opacity: 0.7;
      filter: blur(2px);
    }
    .home-page-image {
      float: left;
      margin: 1em;
      @media (max-width: $tablet-width-min) {
        float: none;
        text-align: center;
      }
    }
    a {
      width: 100%;
      background-color: #f2f2f2;
    }
    .w-100 {
      width: 100%;
    }
    .episode-status {
      position: absolute;
      top: 40px;
      left: 25px;
      width: 250px;
      height: 111px;
      padding-top: 30px;

      @media (max-width: $tablet-width-min) {
        left: 0;
        width: 100%;
      }

      i {
        position: relative;
      }

      .episode-status-background {
        position: absolute;
        top: 0;
        left: -5px;
        width: 257px;
        height: 147px;

        @media (max-width: $tablet-width-min) {
          left: calc(50% - 125px);
        }
      }
    }
  }
  .home-image-list-item-locked {
    border: 2px solid gray;
    cursor: unset;
    background-color: #dfdfdf;
  }
  .mdc-list-item__graphic {
    max-width: 250px;
  }
  .mdc-list-item__text {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .episode-icon {
    text-align: center;
    width: 100%;
    font-size: 2em;
  }

  .episode-title {
    font-size: 1.25em;
    font-weight: 500;
    white-space: normal;
  }
  @media (max-width: $tablet-width-min) {
    .episode-progress.locked {
      font-size: 1em;
    }

    .mdc-list-item__text {
      padding-top: 0;
    }
  }
}

.locked {
  width: 100%;
}

.locked-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.mdc-image-list__image-aspect-container,
.mdc-image-list__label {
  width: 100%;
  position: relative;
}

.mdc-form-field.radio-with-text {
  width: 100%;
}

.mdc-list-item__secondary-text.episode-description {
  font-size: 1.2rem;
  text-overflow: unset;
  white-space: unset;
  margin-top: 1.5em;
}

.episode-progress {
  position: relative;
  color: theme.$secondary;
  font-size: 26px;
  text-align: center;
  -webkit-text-stroke: 0.5px black;
  font-weight: bold;
}

.episode-icon.fa-check {
  color: $success;
}

.episode-icon.fa-ellipsis,
.episode-icon.fa-lock {
  color: theme.$secondary;
  -webkit-text-stroke: 0.5px black;
}

.mdc-card {
  i {
    display: none;
  }

  &.selected {
    &.correct {
      @include card.fill-color(lightGreen);
      i {
        color: $success;
        display: initial;
      }
    }

    &.incorrect {
      @include card.fill-color(pink);
      i {
        color: red;
        display: initial;
      }
    }
  }
}

.mdc-card__media-content {
  position: unset;
  top: unset;
  padding-bottom: 8px;
}

.video-wrapper {
  padding: 50% 0 0 0;
  position: relative;

  // for loading gif
  background-image: url('images/loading-loader.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;

  @media (max-width: $tablet-width-min) {
    margin-top: 2.5%;
    padding: 25% 0;
  }

  .vimeo-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.accordion-vimeo-video {
  width: 100%;
  height: 30rem;
  @media (max-width: $mobile-width-max) {
    height: 12rem;
  }
  @media (min-width: $tablet-width-min) and (max-width: $tablet-width-max) {
    height: 26rem;
  }
}

.mdc-banner .mdc-banner__fixed {
  min-height: 52px;

  &.alert {
    @include banner.fill-color(#fff9c4);
  }

  &.notice {
    @include banner.fill-color(#e7faf6);
  }

  .mdc-banner__text {
    font-weight: 600;
  }
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 32px;

  @media (max-width: $tablet-width-min) {
    padding-top: 28px;
  }
}

.grabbable {
  cursor: grabbing;
}

#topnav {
  @media (max-width: $tablet-width-min) {
    display: none;
  }
}

#mobilenav {
  @media (min-width: $mobile-width-max) {
    display: none;
  }
}

// mobile styles
@media (max-width: $tablet-width-min) {
  .mobilebutton {
    display: flex;
    padding: 20px 0px;
    margin: 20px 10px 20px 10px;
    border: solid 1px;
  }

  ul,
  ol {
    padding-inline-start: 0;
  }

  li {
    margin-top: 10px;
    ul,
    ol {
      padding-inline-start: 25px;
    }
  }
  .accordion > * {
    ul,
    ol {
      padding-left: 15px;
    }
  }
}

li {
  margin-top: 15px;
}

.text-center {
  text-align: center;
}

.message-send {
  width: 40%;
}

.accordion {
  overflow: hidden;

  .tab {
    overflow: hidden;

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    input:checked {
      ~ .tab-label {
        .accordion-button {
          transform: rotate(-180deg);
          transition: all 0.5s;
        }
      }

      ~ .tab-content {
        max-height: 100em;
        transition: all 2s;
        // https://www.drupal.org/project/flexslider/issues/1748514
        -webkit-transform: translate3d(0px, 0px, 1px);
      }
    }

    &-label {
      display: flex;
      justify-content: space-between;

      .accordion-button {
        line-height: 2em;
        transform-origin: 0.3em 1em;
        transition: all 0.5s;
      }
    }

    &-content {
      max-height: 0;
      padding: 0 1em;
      transition: all 0.5s;
    }
  }

  .tab-content {
    padding: 0;

    .completed {
      color: $success;
    }

    .progress {
      color: theme.$primary;
    }
  }
}

.tab-label {
  background-color: theme.$primary;
  padding: 0 20px;
  margin: 10px 0;

  h2,
  .accordion-button {
    color: theme.$background;
    padding-top: 6px;

    @media (max-width: $tablet-width-min) {
      padding-top: unset;
    }
  }
}

.list-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid theme.$secondary;

  .mdc-list-item {
    flex-grow: 1;
  }

  .start-spacer {
    margin-right: 48px;
  }

  .fa-check,
  .fa-lock {
    padding-top: 8px;
  }
  .mdc-list-item__content {
    white-space: unset;
  }
}

.logo {
  padding-top: 10px;
  @media (max-width: $mobile-width-max) {
    padding-top: 15px;
  }
}

.large-logo {
  max-height: 250px;
  @media (max-width: $tablet-width-min) {
    max-width: 80vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.centered-text {
  text-align: center;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 60vw;
}

.mdc-slider {
  margin: 0 50px;
  @media (max-width: $tablet-width-min) {
    margin: 0 2em;
  }
}

.mdc-slider__value-indicator-container {
  width: max-content;

  .mdc-slider__value-indicator {
    height: fit-content;
  }
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin: 0 16px;

  p {
    margin: 0;
  }
}

.slider-left-end {
  width: 5px;
  height: 20px;
  margin-top: -8px;
  background-color: theme.$primary;
}

.slider-right-end {
  width: 5px;
  height: 20px;
  margin-top: -20px;
  background-color: theme.$secondary;
  margin-left: 100%;
}

.slider-result {
  @media (max-width: $tablet-width-min) {
    padding: 0 2em;
  }
}

.fullwidth {
  width: 100%;
}

.mdc-linear-progress.homepage-progress {
  display: inline;
  position: absolute;
  width: 60%;
  margin-top: 10px;
  .mdc-linear-progress__buffer {
    width: 60%;
  }
}

.mdc-linear-progress {
  margin-bottom: 20px;
  height: 10px;
  @media (max-width: $tablet-width-min) {
    margin-bottom: 10px;
    height: 4px;
  }
}

.mdc-linear-progress__bar {
  background-image: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    blue,
    violet
  );
}

.mdc-linear-progress__bar-inner {
  display: none;
}

.mdc-radio {
  @include radio.checked-stroke-color(#5721e5);
  @include radio.ink-color(#5721e5);
}

.radio-pre-label {
  color: theme.$primary;
  font-size: 22px;
  font-weight: bold;
}

audio {
  margin: 0 15%;
  width: 70%;
  @media (max-width: $tablet-width-min) {
    margin: 2vw;
    width: 95%;
  }
}

.mdc-button__label {
  font-size: 18px;
}

.mdc-button--raised {
  height: fit-content;
  min-height: 36px;
}

.single-button {
  margin: 10px 0;
  text-align: center;
}

.booster-goal-setting {
  .mdc-form-field {
    align-items: unset;
  }
}

.barrier-image {
  display: inline-block;
  margin: 0 auto;
  max-width: 225px !important;
}

.goal-setting {
  .mdc-form-field {
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    max-width: 450px;
    min-height: 60px;
    margin: 5px 0;
    padding: 5px;
    width: 100%;

    &.selected {
      background-color: theme.$secondary;
    }
  }
}

// to-do list
.to-do-list-col {
  display: inline-block;

  p {
    margin-block-end: 0;
  }

  .mdc-button {
    vertical-align: inherit;
  }
}
.to-do-list {
  li {
    margin-top: 10px;
    line-height: 1.5em;
    @media (max-width: $tablet-width-min) {
      margin-top: 15px;
    }
  }
}

// good-better-best activities
.good-better-best-option {
  label {
    margin-top: 0px;
  }
  margin-bottom: 0.75em;
}

.solid {
  border-top: 3px solid #bbb;
}

.smaller-checkbox {
  label {
    font-size: 16px;
  }
}

.smaller-radio {
  @media (max-width: $tablet-width-min) {
    font-size: 14px;
  }
}

.prep-form-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  .prep-locator-form {
    display: flex;
    justify-content: center;
    width: 40%;
    border: 1px solid black;
    border-radius: 0.5rem;
    padding: 0 1rem 0.5rem 1rem;

    @media (max-width: $mobile-width-max) {
      width: 100%;
    }

    .mdc-text-field {
      @media (max-width: $mobile-width-max) {
        width: 100%;
      }
    }
  }
}

.prep-locator-input {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;

  @media (max-width: $mobile-width-max) {
    display: initial;
  }

  label {
    padding-right: 1rem;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    line-height: 0;
    width: 30%;

    @media (max-width: $mobile-width-max) {
      text-align: left;
      width: initial;
    }
  }
  input {
    align-self: flex-end;
  }
}

.sub-label {
  font-size: small;
}
